import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="legal">
      <h2>Politique de confidentialité</h2>
        <h3>1. Gestion des données personnelles</h3>
        <p className="text-legal">
          En France, les données personnelles sont notamment protégées par la
          loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004,
          l'article L. 226-13 du Code pénal et la Directive Européenne du 24
          octobre 1995. À l'occasion de l'utilisation du site
          www.webgencia.fr, peuvent êtres recueillies : l'URL des liens
          par l'intermédiaire desquels l'utilisateur a accédé au site
          www.webgencia.fr, le fournisseur d'accès de l'utilisateur,
          l'adresse de protocole Internet (IP) de l'utilisateur. En tout état de
          cause Nicolas COHEN ne collecte des informations personnelles
          relatives à l'utilisateur que pour le besoin de certains services
          proposés par le site www.webgencia.fr. L'utilisateur fournit
          ces informations en toute connaissance de cause, notamment lorsqu'il
          procède par lui-même à leur saisie. Il est alors précisé à
          l'utilisateur du site www.webgencia.fr l'obligation ou non de
          fournir ces informations. Conformément aux dispositions des articles
          38 et suivants de la loi 78-17 du 6 janvier 1978 relative à
          l'informatique, aux fichiers et aux libertés, tout utilisateur dispose
          d'un droit d'accès, de rectification et d'opposition aux données
          personnelles le concernant, en effectuant sa demande écrite et signée,
          accompagnée d'une copie du titre d'identité avec signature du
          titulaire de la pièce, en précisant l'adresse à laquelle la réponse
          doit être envoyée. Aucune information personnelle de l'utilisateur du
          site www.webgencia.fr n'est publiée à l'insu de
          l'utilisateur, échangée, transférée, cédée ou vendue sur un support
          quelconque à des tiers. Seule l'hypothèse du rachat de Nicolas COHEN et de ses droits permettrait la transmission des dites
          informations à l'éventuel acquéreur qui serait à son tour tenu de la
          même obligation de conservation et de modification des données vis à
          vis de l'utilisateur du site www.webgencia.fr. Le site n'est
          pas déclaré à la CNIL car il ne recueille pas d'informations
          personnelles. Les bases de données sont protégées par les dispositions
          de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars
          1996 relative à la protection juridique des bases de données.
        </p>
        <h3>2. Liens hypertextes et cookies</h3>
        <p className="text-legal">
          Le site www.webgencia.fr contient un certain nombre de liens
          hypertextes vers d'autres sites, mis en place avec l'autorisation de
          Nicolas COHEN. Cependant, Nicolas COHEN n'a pas la possibilité
          de vérifier le contenu des sites ainsi visités, et n'assumera en
          conséquence aucune responsabilité de ce fait. La navigation sur le
          site www.webgencia.fr est susceptible de provoquer
          l'installation de cookie(s) sur l'ordinateur de l'utilisateur. Un
          cookie est un fichier de petite taille, qui ne permet pas
          l'identification de l'utilisateur, mais qui enregistre des
          informations relatives à la navigation d'un ordinateur sur un site.
          Les données ainsi obtenues visent à faciliter la navigation ultérieure
          sur le site, et ont également vocation à permettre diverses mesures de
          fréquentation. Le refus d'installation d'un cookie peut entraîner
          l'impossibilité d'accéder à certains services. L'utilisateur peut
          toutefois configurer son ordinateur de la manière suivante, pour
          refuser l'installation des cookies : Sous Internet Explorer : onglet
          outil / options internet. Cliquez sur Confidentialité et choisissez
          Bloquer tous les cookies. Validez sur Ok. Sous Netscape : onglet
          édition / préférences. Cliquez sur Avancées et choisissez Désactiver
          les cookies. Validez sur Ok. Cookies de sessions présents sur ce site
          : PHPSESSID : Cookie de session propre à PHP Autres cookies : _ga,
          _gat : Cookies propres à Google Analytics (statistiques)
        </p>

    </section>
  );
};

export default PrivacyPolicy;

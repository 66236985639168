import React, { useState, useRef, useEffect } from "react";
import { useTrail, animated } from "react-spring";
import "./SkillsStyle.css";
import ImageJavaScript from "../../assets/javascript.webp";
import ImageGitHub from "../../assets/github.webp";
import ImageReact from "../../assets/react.webp";
import ImageRedux from "../../assets/redux.webp";

const Skills = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [activeBulleId, setActiveBulleId] = useState(null);
  const skillsRef = useRef(null);

  const handleBulleClick = (id) => {
    setIsExpanded(!isExpanded);
    setActiveBulleId(id);
  };

  const bulles = [
    {
      id: 1,
      icon: "fa-solid fa-graduation-cap",
      text: "BAC +2 Développeur Web",
      description:
        "Diplômé d'un établissement privé enregistré au rectorat de l'Académie de Paris.",
    },
    {
      id: 2,
      icon: "fa-solid fa-eye",
      text: "Référencement SEO",
      description: "Une visibilité maximale dans les moteurs de recherche.",
    },
    {
      id: 3,
      icon: "fa-solid fa-circle-check",
      text: "Validation W3C",
      description:
        "Votre garantie d'un site web développé selon les meilleures pratiques internationales.",
    },
    {
      id: 4,
      icon: "fa-solid fa-code",
      text: "HTML - CSS",
      description:
        "Structure et esthétique web en parfaite harmonie pour un site web qui vous ressemble.",
    },
    {
      id: 5,
      icon: "image-bulle image-js",
      iconSrc: ImageJavaScript,
      text: "JavaScript",
      description:
        "Interactions envoûtantes et fonctionnalités avancées pour un site web dynamique.",
    },
    {
      id: 6,
      icon: "image-bulle",
      iconSrc: ImageReact,
      text: "React",
      description:
        "Nouvelle technologie révolutionnaire pour concevoir des sites web évolutifs.",
    },
    {
      id: 7,
      icon: "image-bulle",
      iconSrc: ImageRedux,
      text: "Redux",
      description: "Gestion d'état avancée pour des sites web développés sous React.",
    },
    {
      id: 8,
      icon: "fa-solid fa-mobile-screen",
      text: "Responsive Design",
      description:
        "Sites web adaptatifs pour une expérience utilisateur fluide sur tous les appareils.",
    },
    {
      id: 9,
      icon: "image-bulle",
      iconSrc: ImageGitHub,
      text: "Git & GitHub",
      description:
        "Outils essentiels pour la gestion de versions et la collaboration lors du développement de projets.",
    },
  ];

  const softSkills = [
    {
      id: 1,
      icon: "fa-solid fa-comments",
      text: "Communication",
    },
    {
      id: 2,
      icon: "fa-solid fa-fire",
      text: "Autonomie",
    },
    {
      id: 3,
      icon: "fa-solid fa-sliders",
      text: "Adaptabilité",
    },
    {
      id: 4,
      icon: "fa-solid fa-wand-magic-sparkles",
      text: "Créativité",
    },
  ];

  const trail = useTrail(bulles.length, {
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0)" : "translateY(20px)",
    config: { mass: 1, tension: 210, friction: 20 },
  });

  const trailSoftSkills = useTrail(softSkills.length, {
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0)" : "translateY(20px)",
    config: { mass: 1, tension: 210, friction: 20 },
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { rootMargin: "0px 0px -300px 0px" } // Adjusted animation start
    );

    const currentRef = skillsRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section ref={skillsRef} id="skills" className="skills">
      <h2 className="title-section">Nos compétences</h2>
      <div className="bulles">
        {trail.map((props, index) => (
          <animated.div
            key={bulles[index].id}
            className={`bulle ${isExpanded && activeBulleId === bulles[index].id ? "active" : ""}`}
            onClick={() => handleBulleClick(bulles[index].id)}
            style={props}
          >
            <div className="bulle-firstline">
              {bulles[index].icon.startsWith("fa-solid") ? (
                <i className={bulles[index].icon}></i>
              ) : (
                <img
                  className={bulles[index].icon}
                  src={bulles[index].iconSrc}
                  alt=""
                />
              )}
              <p className="skills-text">{bulles[index].text}</p>
            </div>
            <p className={`bulle-lastline ${isExpanded && activeBulleId === bulles[index].id ? "active" : ""}`}>
              {bulles[index].description}
            </p>
          </animated.div>
        ))}
      </div>
      <div className="quote">
        <p className="quote-content">
          La seule façon de faire du bon travail, c’est d’aimer ce que l’on fait.
        </p>
        <p className="quote-author">Steve Jobs, Stanford 2005</p>
      </div>
      <div className="bulles">
        {trailSoftSkills.map((props, index) => (
          <animated.div
            key={softSkills[index].id}
            className={`bulle-softskills ${isVisible ? "active" : ""}`}
            style={props}
          >
            <i className={softSkills[index].icon}></i>
            <p className="skills-text">{softSkills[index].text}</p>
          </animated.div>
        ))}
      </div>
    </section>
  );
};

export default Skills;

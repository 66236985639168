import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./HeaderStyle.css";
import LogoHeader from "../../assets/logo-header.webp";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleLogoClick = () => {
    if (location.pathname === "/") {
      scrollToTop();
    } else {
      navigate("/");
    }
    closeMenu();
  };

  const handleSectionClick = async (sectionId) => {
    const section = document.getElementById(sectionId);
    const headerHeight = document.getElementById("header").offsetHeight;

    if (section) {
      const topOffset = section.offsetTop - headerHeight;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }

    if (
      location.pathname === "/mentions-legales" ||
      location.pathname === "/politique-de-confidentialite"
    ) {
      await navigate("/");
      setTimeout(() => {
        const updatedSection = document.getElementById(sectionId);
        if (updatedSection) {
          const updatedTopOffset = updatedSection.offsetTop - headerHeight;
          window.scrollTo({ top: updatedTopOffset, behavior: "smooth" });
        }
      }, 0);
    }

    closeMenu();
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header id="header">
      <div className={`nav-ico ${isMenuOpen ? "menu-open" : ""}`}>
        <Link to="/" className="header-link">
          <img
            className="logo-header"
            src={LogoHeader}
            alt="Logo Webgencia"
            onClick={handleLogoClick}
          />
        </Link>
        <i
          className={`fa-solid ${isMenuOpen ? "fa-xmark" : "fa-bars"}`}
          onClick={handleMenuToggle}
        ></i>
      </div>
      <div className={`nav-buttons ${isMenuOpen ? "menu-open" : ""}`}>
        <button
          className="header-link"
          onClick={() => handleSectionClick("services")}
        >
          Services
        </button>
        <button
          className="header-link"
          onClick={() => handleSectionClick("skills")}
        >
          Compétences
        </button>
        <button
          className="header-link"
          onClick={() => handleSectionClick("projects")}
        >
          Projets
        </button>
        <button
          className="header-link"
          onClick={() => handleSectionClick("about")}
        >
          À propos
        </button>
        <button
          className="header-link"
          onClick={() => handleSectionClick("prices")}
        >
          Tarifs
        </button>
        <button
          className="header-link"
          onClick={() => handleSectionClick("contact")}
        >
          Contact
        </button>
      </div>
    </header>
  );
};

export default Header;

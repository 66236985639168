import React, { useEffect } from "react";
import Banner from '../../components/Banner/Banner';
import Offer from '../../components/Offer/Offer';
import Services from '../../components/Services/Services';
import Skills from '../../components/Skills/Skills';
import Projects from '../../components/Projects/Projects';
import About from '../../components/About/About';
import Prices from '../../components/Prices/Prices';
import Contact from '../../components/Contact/Contact';

function Index() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner />
      <Offer />
      <Services />
      <Skills />
      <Projects />
      <About />
      <Prices />
      <Contact />
    </div>
  );
}

export default Index;

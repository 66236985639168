import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MapWrapper from "../Map/MapWrapper";
import emailjs from "@emailjs/browser";
import "./ContactStyle.css";

const Contact = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [lock, setLock] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !message || !lock) {
      toast.error("Veuillez remplir tous les champs obligatoires !");
      return;
    }
    if (!isChecked) {
      toast.error("Veuillez accepter la politique de confidentialité !");
      return;
    }
    if (lock !== "4") {
      toast.error("Sécurité robot non valide !");
      return;
    }
  
    emailjs
      .sendForm(
        "service_5kgregh",
        "template_v9rp1ds",
        form.current,
        "sDmovEqbQLVL2hd32"
      )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          toast.success("Votre message a été envoyé avec succès !");
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setLock("");
          setIsChecked(false);
        } else {
          toast.error("Une erreur s'est produite lors de l'envoi du message.");
        }
      })
      .catch((error) => {
        toast.error("Une erreur s'est produite lors de l'envoi du message.");
        console.error(error);
      });
  };
  
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <section id="contact" className="contact">
      <h2 className="title-section">Contactez-nous</h2>
      <h3 className="legend-title-contact">
        Une idée ? Un projet ? N'hésitez pas à{" "}
        <span className="estimate">demander un devis</span> ! [GRATUIT]
      </h3>
      <div>
        <div className="containers-contact">
          <div className="information">
            <div className="retail-information">
              <i className="fa-solid fa-location-dot"></i>
              <p className="text-information">
                34 avenue des Champs-Élysées,
                <br />
                Paris - 75008
              </p>
            </div>
            <div className="retail-information">
              <i className="fa-solid fa-envelope"></i>
              <p className="text-information">
                <a className="link-contact" href="mailto:contact@webgencia.fr">
                  contact@webgencia.fr
                </a>
              </p>
            </div>
            <div className="retail-information">
              <i className="fa-solid fa-phone"></i>
              <p className="text-information">
                <a className="link-contact" href="tel:+33783775919">
                  +33 (0)7 83 77 59 19
                </a>
              </p>
            </div>
            <div className="retail-information">
              <i className="fa-solid fa-gear"></i>
              <p className="text-siren">
                S.I.R.E.N
                <br /> 847 645 835
              </p>
            </div>
          </div>
          <div className="map-container map map-desktop-tablet">
            <MapWrapper />
          </div>
          <form className="form" method="post" onSubmit={handleSubmit} ref={form}>
            <div className="form-retail">
              <i className="fa-solid fa-user icon-form"></i>
              <input
                className="input-contact"
                type="text"
                name="name"
                placeholder="Nom & Prénom"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <p className="icon-form">*</p>
            </div>
            <div className="form-retail">
              <i className="fa-solid fa-envelope icon-form"></i>
              <input
                className="input-contact"
                type="email"
                name="email"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="icon-form">*</p>
            </div>
            <div className="form-retail">
              <i className="fa-solid fa-phone icon-form icon-width"></i>
              <input
                className="input-contact input-width"
                type="tel"
                name="tel"
                placeholder="Téléphone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form-message">
              <textarea
                className="input-contact input-width input-height"
                placeholder="Message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <p className="icon-form asterisk-message">*</p>
            </div>
            <div className="form-retail">
              <i className="fa-solid fa-lock icon-form"></i>
              <input
                className="input-contact"
                type="text"
                placeholder="3 + 1 = ?"
                value={lock}
                onChange={(e) => setLock(e.target.value)}
              />
              <p className="icon-form">*</p>
            </div>
            <label className="agree">
              <input
                className="accept"
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <p className="accept-text">
                En soumettant ce formulaire, j’affirme avoir pris connaissance
                de la{" "}
                <Link
                  className="link-accept"
                  to="/politique-de-confidentialite"
                >
                  politique de confidentialité
                </Link>{" "}
                de Webgencia.
              </p>
            </label>
            <button className="button-send" type="submit">
              Envoyer
            </button>
          </form>
        </div>
        <div className="map-container map map-mobile">
          <MapWrapper />
        </div>
        <ToastContainer />
      </div>
    </section>
  );
};

export default Contact;

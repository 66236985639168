import React from "react";
import "./PricesStyle.css";

const Prices = () => {

  const handleSectionClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    const headerHeight = document.getElementById("header").offsetHeight;

    if (section) {
      const topOffset = section.offsetTop - headerHeight;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  return (
    <section id="prices" className="prices">
      <h2 className="title-section">Tarifs</h2>
      <h3 className="legend-title-price">
        Découvrez les offres de création de sites internet personnalisés.
      </h3>
      <section className="container-price">
        <div className="cards-price">
          <div className="sites-cards">
            <div className="card-price">
              <p className="title-card-price">
                <strong>SITE VITRINE</strong>
              </p>
              <p>
                <span className="from-price">À PARTIR DE</span> <br />
                <span className="price-price">
                  <strong>1</strong>
                </span>
                <span className="currency-symbol">€ HT *</span>
              </p>
              <hr className="centered-line" />
              <div className="retails">
                <p className="retail-a">
                  <strong>Développé SUR-MESURE</strong>
                </p>
                <p className="retail-b">Création d'une charte graphique</p>
                <p className="retail-a">
                  Domaine + hébergement + mail
                  <br /> pendant 12 mois
                </p>
                <p className="retail-b">Interface administrateur</p>
                <p className="retail-a">Site responsive</p>
                <p className="retail-b">Mise en place du contenu</p>
                <p className="retail-a">Formulaire de contact & Map</p>
                <p className="retail-b">
                  Liens de partage sur les réseaux sociaux
                </p>
                <p className="retail-a">
                  Optimisation du référencement et des performances
                </p>
                <p className="retail-b">Statistiques des visites</p>
                <p className="retail-a">Mise en ligne du site</p>
                <p className="retail-b">Assistance par mail & téléphone</p>
                <p className="retail-a">Formation à l'utilisation du site</p>
                <button
                  className="button-price"
                  onClick={() => handleSectionClick("contact")}
                >
                  Demander un devis
                </button>
              </div>
            </div>
            <div className="card-price">
              <p className="title-card-price">
                <strong>SITE E-COMMERCE</strong>
              </p>
              <p>
                <span className="from-price">À PARTIR DE</span> <br />
                <span className="price-price">
                  <strong>3897</strong>
                </span>
                <span className="currency-symbol">€ HT</span>
              </p>
              <hr className="centered-line" />
              <div className="retails">
                <p className="retail-a">
                  <strong>Développé SUR-MESURE</strong>
                </p>
                <p className="retail-b">Création d'une charte graphique</p>
                <p className="retail-a">
                  Domaine + hébergement + mail
                  <br />
                  pendant 12 mois
                </p>
                <p className="retail-b">Interface administrateur</p>
                <p className="retail-a">Site responsive</p>
                <p className="retail-b">Mise en place du contenu</p>
                <p className="retail-a">Formulaire de contact & Map</p>
                <p className="retail-b">
                  Liens de partage sur les réseaux sociaux
                </p>
                <p className="retail-a">
                  Optimisation du référencement et des performances
                </p>
                <p className="retail-b">Statistiques des visites</p>
                <p className="retail-a">Mise en ligne du site</p>
                <p className="retail-b">Assistance par mail & téléphone</p>
                <p className="retail-a">Formation à l'utilisation du site</p>
                <button
                  className="button-price"
                  onClick={() => handleSectionClick("contact")}
                >
                  Demander un devis
                </button>
              </div>
            </div>
          </div>
          <div className="specific-cards">
            <div className="card-price">
              <p className="title-card-price">
                <strong>FORFAIT MAINTENANCE</strong>
              </p>
              <p>
                <span className="maintenance-price">
                  <strong>50</strong>
                </span>
                <span className="maintenance-symbol">€/HEURE **</span>
                <span className="from-price">OU</span>
                <br />
                <span className="price-price">
                  <strong>400</strong>
                </span>
                <span className="currency-symbol">€/AN ***</span>
              </p>
              <hr className="centered-line" />
              <div className="retails">
                <p className="retail-a">Mises à jour site internet & plugins</p>
                <p className="retail-b">
                  Petites modifications graphiques ou de contenu
                </p>
                <p className="retail-a">Sauvegardes externalisées mensuelles</p>
                <p className="retail-b">
                  Optimisation du référencement et des performances
                </p>
                <p className="retail-a">Restauration en cas de piratage</p>
                <button
                  className="button-price"
                  onClick={() => handleSectionClick("contact")}
                >
                  Me contacter
                </button>
              </div>
            </div>
            <div className="card-price">
              <p className="title-card-price">
                <strong>DÉVELOPPEMENTS SPÉCIFIQUES</strong>
              </p>
              <p className="from-price">TAUX JOURNALIER MOYEN</p>
              <p>
                <span className="price-price">
                  <strong>300</strong>
                </span>
                <span className="currency-symbol">€/JOUR</span>
              </p>
              <hr className="centered-line" />
              <button
                className="button-price retails"
                onClick={() => handleSectionClick("contact")}
              >
                Me contacter
              </button>
            </div>
          </div>
        </div>
        <p className="text-asterix">
          <strong>Prix à titre informatif</strong> : Changement possible selon
          les demandes et les choix du client. <br />
          (*) Sous réserve de souscrire au Forfait Maintenance annuel. <br />
          (**) Estimation du temps de travail appuyé par un devis. <br />
          (***) Maintenance annuelle (engagement 12 mois). <br />
          <strong> Droit d'auteur & propriété intellectuelle</strong> : Vous
          êtes à la fin du projet, propriétaire de l'ensemble des sources
          (fichiers) composant celui-ci.
          <br /> <strong>Paiement possible en plusieurs fois.</strong>
        </p>
      </section>
    </section>
  );
};

export default Prices;

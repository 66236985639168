import React, { useEffect } from "react";
import "./LegalStyle.css";

const Legal = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="legal">
        <h2>Mentions légales</h2>
        <h3>1. Présentation du site</h3>
        <p className="text-legal">
          En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
          confiance dans l'économie numérique, il est précisé aux utilisateurs
          du site www.webgencia.fr l'identité des différents
          intervenants dans le cadre de sa réalisation et de son suivi :<br />
          Propriétaire : Nicolas COHEN – nicolas.cohen@webgencia.fr - 34 Avenue des Champs Élysées, 75008 Paris<br />
          Créateur : Nicolas COHEN – www.webgencia.fr<br />
          Responsable publication : Nicolas COHEN<br />
          Webmaster : Nicolas COHEN – nicolas.cohen@webgencia.fr<br />
          Hébergeur : OVH – 2 Rue Kellermann - 59100 Roubaix - France
        </p>
        <h3>
          2. Conditions générales d'utilisation du site et des services
          proposés
        </h3>
        <p className="text-legal">
          L'utilisation du site www.webgencia.fr implique l'acceptation
          pleine et entière des conditions générales d'utilisation ci-après
          décrites. Ces conditions d'utilisation sont susceptibles d'être
          modifiées ou complétées à tout moment, les utilisateurs du site
          www.webgencia.fr sont donc invités à les consulter de manière
          régulière. Ce site est normalement accessible à tout moment aux
          utilisateurs. Une interruption pour raison de maintenance technique
          peut être toutefois décidée par www.webgencia.fr, qui
          s'efforcera alors de communiquer préalablement aux utilisateurs les
          dates et heures de l'intervention. Le site www.webgencia.fr
          est mis à jour régulièrement par Nicolas COHEN. De la même façon,
          les mentions légales peuvent être modifiées à tout moment : elles
          s'imposent néanmoins à l'utilisateur qui est invité à s'y référer le
          plus souvent possible afin d'en prendre connaissance.
        </p>
        <h3>3. Description des services fournis</h3>
        <p className="text-legal">
          Le site www.webgencia.fr a pour objet de fournir une
          information concernant l'ensemble des activités de la société. Nicolas COHEN s'efforce de fournir sur le site www.webgencia.fr
          des informations aussi précises que possible. Toutefois, il ne pourra
          être tenu responsable des omissions, des inexactitudes et des carences
          dans la mise à jour, qu'elles soient de son fait ou du fait des tiers
          partenaires qui lui fournissent ces informations. Tous les
          informations indiquées sur le site www.webgencia.fr sont
          données à titre indicatif, et sont susceptibles d'évoluer. Par
          ailleurs, les renseignements figurant sur le site
          www.webgencia.fr ne sont pas exhaustifs. Ils sont donnés sous
          réserve de modifications ayant été apportées depuis leur mise en
          ligne.
        </p>
        <h3>4. Limitations contractuelles sur les données techniques</h3>
        <p className="text-legal">
          Le site utilise la technologie JavaScript. Le site Internet ne pourra
          être tenu responsable de dommages matériels liés à l'utilisation du
          site. De plus, l'utilisateur du site s'engage à accéder au site en
          utilisant un matériel récent, ne contenant pas de virus et avec un
          navigateur de dernière génération mis-à-jour.
        </p>
        <h3>5. Propriété intellectuelle et contrefaçons</h3>
        <p className="text-legal">
          Nicolas COHEN est propriétaire des droits de propriété
          intellectuelle ou détient les droits d'usage sur tous les éléments
          accessibles sur le site, notamment les textes, images, graphismes,
          logo, icônes, sons, logiciels. Toute reproduction, représentation,
          modification, publication, adaptation de tout ou partie des éléments
          du site, quel que soit le moyen ou le procédé utilisé, est interdite,
          sauf autorisation écrite préalable de : Nicolas COHEN. Toute
          exploitation non autorisée du site ou de l'un quelconque des éléments
          qu'il contient sera considérée comme constitutive d'une contrefaçon et
          poursuivie conformément aux dispositions des articles L.335-2 et
          suivants du Code de Propriété Intellectuelle.
        </p>
        <h3>6. Limitations de responsabilité</h3>
        <p className="text-legal">
          Nicolas COHEN ne pourra être tenu responsable des dommages directs
          et indirects causés au matériel de l'utilisateur, lors de l'accès au
          site www.webgencia.fr, et résultant soit de l'utilisation
          d'un matériel ne répondant pas aux spécifications indiquées au point
          4, soit de l'apparition d'un bug ou d'une incompatibilité Nicolas COHEN ne pourra également être tenu responsable des dommages
          indirects (tels par exemple qu'une perte de marché ou perte d'une
          chance) consécutifs à l'utilisation du site www.webgencia.fr.
          Des espaces interactifs (possibilité de poser des questions dans
          l'espace contact) sont à la disposition des utilisateurs. Nicolas COHEN se réserve le droit de supprimer, sans mise en demeure
          préalable, tout contenu déposé dans cet espace qui contreviendrait à
          la législation applicable en France, en particulier aux dispositions
          relatives à la protection des données. Le cas échéant, Nicolas COHEN se réserve également la possibilité de mettre en cause la
          responsabilité civile et/ou pénale de l'utilisateur, notamment en cas
          de message à caractère raciste, injurieux, diffamant, ou
          pornographique, quel que soit le support utilisé (texte,
          photographie…).
        </p>
        <h3>7. Droit applicable et attribution de juridiction</h3>
        <p className="text-legal">
          Tout litige en relation avec l'utilisation du site
          www.webgencia.fr est soumis aux tribunaux francais compétents
          statuant selon le droit français.
        </p>
        <h3>8. Les principales lois concernées</h3>
        <p className="text-legal">
          Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n°
          2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux
          libertés. Loi n° 2004-575 du 21 juin 2004 pour la confiance dans
          l'économie numérique.
        </p>
        <h3>9. Affiliation</h3>
        <p className="text-legal">
          Ce site participe au Programme Partenaires d’Amazon EU, un programme
          d’affiliation conçu pour permettre à des sites de percevoir une
          rémunération grâce à la création de liens vers Amazon.fr.
        </p>
        <h3>10. Lexique</h3>
        <p className="text-legal">
          Utilisateur : Internaute se connectant, utilisant le site susnommé.
          Informations personnelles : « les informations qui permettent, sous
          quelque forme que ce soit, directement ou non, l'identification des
          personnes physiques auxquelles elles s'appliquent » (article 4 de la
          loi n° 78-17 du 6 janvier 1978).
        </p>
    </section>
  );
};

export default Legal;

import React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from "./Map";

const MapWrapper = () => {
  const render = (status) => {
    if (status === Status.LOADING) {
      return <div>Loading...</div>;
    } else if (status === Status.ERROR) {
      return <div>Error loading Google Maps</div>;
    } else {
      return (
        <div className="map-container">
          <Map />
        </div>
      );
    }
  };

  return <Wrapper apiKey="AIzaSyCLO5e54z0smW3a1Dm4uwp0o3XRsFHZG0A" render={render} />;
};

export default MapWrapper;

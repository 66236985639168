import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ProjectsStyle.css";
import ImageProject1 from "../../assets/portfolio-img1.webp";
import ImageProject2 from "../../assets/portfolio-img2.webp";
import ImageProject3 from "../../assets/portfolio-img3.webp";
import ImageProject4 from "../../assets/portfolio-img4.webp";
import ImageProject5 from "../../assets/portfolio-img5.webp";
import ImageProject6 from "../../assets/portfolio-img6.webp";

const Projects = () => {
  const [filter, setFilter] = useState("Tous");

  const handleFilter = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const projects = [
    {
      id: 1,
      title: "OHMYFOOD",
      category: "Sites e-commerce",
      image: ImageProject1,
      url: "https://nicolas-cohen.github.io/OC-Projet-4/",
      description:
        "Améliorer l'interface d'un site mobile avec des animations CSS.",
    },
    {
      id: 2,
      title: "AUTO-MOTO ÉCOLE JACQUES",
      category: "Sites vitrines",
      image: ImageProject2,
      url: "https://aejacques.fr",
      description:
        "Création d'un site web d'une auto-moto école favorisant la prise de contact.",
    },
    {
      id: 3,
      title: "TM DÉPANNAGES",
      category: "Sites vitrines",
      image: ImageProject3,
      url: "https://tmdepannages.fr/",
      description: "Améliorer la visibilité et le référencement des dépanneurs de l'Oise.",
    },
    {
      id: 4,
      title: "DOMO'TECH ELEC",
      category: "Sites vitrines",
      image: ImageProject4,
      url: "https://domotech-elec.fr/",
      description: "Conception d'un site web pour un expert en électricité et domotique.",
    },
    {
      id: 5,
      title: "COUVREUR MARTIN ANDRÉ",
      category: "Sites vitrines",
      image: ImageProject5,
      url: "https://couvreurmartinandre.fr/",
      description: "Conception d'un site web pour un couvreur spécialisé en toiture.",
    },
    {
      id: 6,
      title: "GESTION MULTI SERVICES",
      category: "Sites vitrines",
      image: ImageProject6,
      url: "https://gestionmultiservices.fr/",
      description:
        "Création d'un site web d'une entreprise de gestion spécialisée dans le nettoyage.",
    },
    // Add other projects here
  ];

  const filteredProjects = projects.filter((project) => {
    if (filter === "Tous") {
      return true;
    } else if (filter === "Sites vitrines") {
      return project.category === "Sites vitrines";
    } else if (filter === "Sites e-commerce") {
      return project.category === "Sites e-commerce";
    }
    return false;
  });

  return (
    <section id="projects" className="projects">
      <h2 className="title-section">Nos projets</h2>
      <ul className="filter-projects">
        <li
          className={`button-filter ${filter === "Tous" ? "active" : ""}`}
          onClick={() => handleFilter("Tous")}
        >
          Tous
        </li>
        <li
          className={`button-filter ${
            filter === "Sites vitrines" ? "active" : ""
          }`}
          onClick={() => handleFilter("Sites vitrines")}
        >
          Sites vitrines
        </li>
        <li
          className={`button-filter ${
            filter === "Sites e-commerce" ? "active" : ""
          }`}
          onClick={() => handleFilter("Sites e-commerce")}
        >
          Sites e-commerce
        </li>
      </ul>
      <div className="cards-projects">
        {filteredProjects.map((project) => (
          <Link className="link card-project" to={project.url} key={project.id} target="_blank" rel="noopener noreferrer">
            <img
              className="image-project"
              src={project.image}
              alt={project.title}
            />
            <div className="content-project">
              <p>
                <strong>{project.title}</strong>
              </p>
              <p>{project.description}</p>
              <p className="content-bulle">Voir le projet</p>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default Projects;

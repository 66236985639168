import React, { useState, useEffect, useRef, useCallback } from "react";
import "./AboutStyle.css";

const About = () => {
  const [title, setTitle] = useState("développeur.");
  const [visible, setVisible] = useState(false);
  const aboutRef = useRef(null);

  const handleScroll = useCallback(() => {
    const aboutSection = aboutRef.current;
    const sectionPosition = aboutSection.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    if (
      sectionPosition.top <= windowHeight * 0.5 &&
      sectionPosition.bottom >= windowHeight * 0.2
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const titles = ["développeurs.", "créateurs.", "freelances."];
    let currentIndex = 0;
    let letterIndex = 0;
    let isDeleting = false;

    const typeEffect = () => {
      const currentTitle = titles[currentIndex];
      if (isDeleting) {
        setTitle(currentTitle.substring(0, letterIndex - 1));
        letterIndex--;
      } else {
        setTitle(currentTitle.substring(0, letterIndex + 1));
        letterIndex++;
      }

      if (isDeleting && letterIndex === 0) {
        isDeleting = false;
        currentIndex = (currentIndex + 1) % titles.length;
      }

      if (!isDeleting && letterIndex === currentTitle.length) {
        isDeleting = true;
        setTimeout(typeEffect, 2000);
      } else {
        setTimeout(typeEffect, 35);
      }
    };

    // Reset the title to the initial state when the component is mounted
    setTitle(titles[0]);

    setTimeout(typeEffect, 0);
  }, []);

  return (
    <section id="about" className="about" ref={aboutRef}>
      <h2 className="title-section title-about">À propos</h2>
      <div className="title-about">
        <h2 className={`dynamic-title ${visible ? "fade-in" : ""}`}>
          Nous sommes des{" "}
          <span className={`dynamic-word ${visible ? "visible" : "hidden"}`}>
            {title}
          </span>
        </h2>
      </div>
      <div className="content-about">
        <p>
          Passionnés par le développement web et le design, nous nous consacrons à créer
          des projets centrés sur l'expérience utilisateur et l'accessibilité.
        </p>
        <p>
          <strong>Experts en référencement</strong>, nous conçevons et concrétisons votre site internet,
          en commençant par l'élaboration du cahier des charges jusqu'à sa mise
          en ligne.
        </p>
        <p>
          Webgencia s'engage à vous accompagner tout au long du processus jusqu'à votre
          satisfaction !
        </p>
      </div>
    </section>
  );
};

export default About;

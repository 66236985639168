import React from "react";
import "./OfferStyle.css";
import imageHandEuro from "../../assets/hand_1_euro.webp";

const Offer = () => {
  const handleSectionClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    const headerHeight = document.getElementById("header").offsetHeight;

    if (section) {
      const topOffset = section.offsetTop - headerHeight;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  return (
    <section className="offer">
      <img
        className="offer-image"
        src={imageHandEuro}
        alt="Bannière de Webgencia"
      />
      <div className="card">OFFRE EXCEPTIONNELLE</div>

      <div className="offer-content">
        <p>
          Un site vitrine pour seulement <strong>1 euro</strong> en souscrivant à notre forfait
          maintenance annuelle !
        </p>
        <p>
          Bénéficiez d'un site internet professionnel et gardez-le à jour tout
          au long de l'année !
        </p>
        <button
          className="button-offer"
          onClick={() => handleSectionClick("prices")}
        >
          En savoir plus
        </button>
      </div>
    </section>
  );
};

export default Offer;

import React from "react";
import "./BannerStyle.css";
import LogoBanner from "../../assets/logo-banner.webp";
import BannerImage from "../../assets/banner.webp";

const Banner = () => {
  return (
    <section className="banner">
      <img
        className="banner-image"
        src={BannerImage}
        alt="Bannière de Webgencia"
      />
      <div className="banner-content">
        <img className="banner-logo" src={LogoBanner} alt="Logo Webgencia" />
        <h1 className="banner-text">
          Le web à votre image.
        </h1>
      </div>
    </section>
  );
};

export default Banner;

import React, { useRef, useState, useEffect } from "react";

const Map = () => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (mapRef.current && !map) {
      const mapInstance = new window.google.maps.Map(mapRef.current, {
        center: { lat: 48.8701865, lng: 2.3083983 },
        zoom: 12,
      });

      new window.google.maps.Marker({
        position: { lat: 48.8701865, lng: 2.3083983 },
        map: mapInstance,
        title: "Webgencia",
      });

      setMap(mapInstance);
    }
  }, [mapRef, map]);

  return <div ref={mapRef} className="map" />;
};

export default Map;

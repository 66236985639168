import React, { useEffect } from "react";
import "./ServicesStyle.css";
import IconFlash from "../../assets/icon-flash.webp";
import IconPowerful from "../../assets/icon-powerful.webp";
import IconApp from "../../assets/icon-app.webp";

const Services = () => {
  useEffect(() => {
    const handleScroll = () => {
      const cards = document.querySelectorAll(".card-services");

      cards.forEach((card) => {
        const cardPosition = card.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (
          cardPosition.top <= windowHeight * 0.8 &&
          cardPosition.bottom >= windowHeight * 0.4
        ) {
          card.classList.add("fade-in");
          card.classList.remove("fade-out");
        } else {
          card.classList.add("fade-out");
          card.classList.remove("fade-in");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section id="services" className="services">
      <h2 className="title-section">Nos services</h2>
      <div className="cards">
        <div className="card-services">
          <img className="icon-card-services" src={IconApp} alt="{altText}" />
          <h3 className="title-card-services">Applications</h3>
          <hr className="centered-line-service" />
          <p>
            Création de sites web, maintenance, optimisation, certificats SSL et
            HTTPS, hébergement web.
          </p>
        </div>
        <div className="card-services">
          <img className="icon-card-services" src={IconFlash} alt="{altText}" />
          <h3 className="title-card-services">Responsive Design</h3>
          <hr className="centered-line-service" />
          <p>
            Compatible sur tous les supports :<br/>ordinateur, tablette et mobile.
            <br />
            <span className="text-responsive-design">
            Compatible pour nous tous.
            <br />
            Partout.
            </span>
          </p>
        </div>
        <div className="card-services">
          <img className="icon-card-services" src={IconPowerful} alt="{altText}" />
          <h3 className="title-card-services">Puissant & Green</h3>
          <hr className="centered-line-service" />
          <p>
            Une expérience fluide et optimisée.
            <br /> Appliquant également le{" "}
            <a
              className="green-it"
              href="https://www.greenit.fr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Green IT</strong>
            </a>
            , pour une informatique durable et écologique.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Services;
